<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TopLevelLeftNav />

    <div class="page">
      <div class="subpage-banner"></div>
        <div class="subpage-content">

        <section>
          <div class="content-band">
          <div class="pagehead-title">
            
            <h1>Flexbox Utilities</h1>
            <div class="pagehead-link">
              <a href=""><img src="/html-components/images/svg/link.svg" alt=""/></a>
            </div>
          </div>
            <p>
              Flex utilities can be used to apply flexbox behaviors to elements by using css utility classes.
            </p>
          </div>
        </section>

        <div class="divider max-w1200"></div>

        <div class="flex-sidebar flex-wrap">
        <div class="container max-w1000">

          <section id="flex-containers">
            <div class="content-band">
              <h2>Flex containers</h2>
              <p class="mb-30">
                Use these classes to make an element lay out its content using the flexbox model. Each direct 
                child of the flex container will become a flex item.
              </p>


            <div class="mt-30"> 

              <div class="flex flex-col max-w800 white">

                <div class="flex">
                  <div class="container plr-30 ptb-20 basis-25 gray-250">Utility class</div>
                  <div class="container plr-30 ptb-20 basis-75 gray-250">Description</div>
                </div>
                
                <div class="flex border-bottom">
                  <div class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100">.flex </div>
                  <div class="container plr-30 ptb-20 basis-75">The element behaves like a block and lays out its content using the flexbox model.</div>
                </div>

                <div class="flex">
                  <div class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100">.inline-flex </div>
                  <div class="container plr-30 ptb-20 basis-75">The element behaves like an inline element and lays out its content using the flexbox model.</div>
                </div>

              </div>
              </div>
            </div>
          </section>

          <section id="flex-direction">
            <div class="content-band">
              <h2>Flex direction</h2>
              <p>
                Use these classes to define the orientation of the main axis (row or column). By default, flex items will display in a row. Use .flex-column 
                to change the direction of the main axis to vertical.
              </p>
              
              <div class="mt-30"> 
              

              <div class="flex flex-col max-w800 white">

                <div class="flex">
                  <div class="container plr-30 ptb-20 basis-25 gray-250">Utility class</div>
                  <div class="container plr-30 ptb-20 basis-75 gray-250">Description</div>
                </div>
                
                <div class="flex border-bottom">
                  <div class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100">.flex-row</div>
                  <div class="container plr-30 ptb-20 basis-75 flex align-center">The main axis runs left to right (default).</div>
                </div>

                <div class="flex border-bottom">
                  <div class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100">.flex-row-reverse</div>
                  <div class="container plr-30 ptb-20 basis-75 flex align-center">The main axis runs right to left.</div>
                </div>

                <div class="flex border-bottom">
                  <div class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100">.flex-col</div>
                  <div class="container plr-30 ptb-20 basis-75 flex align-center">The main axis runs top to bottom.</div>
                </div>

                <div class="flex">
                  <div class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100">.flex-col-reverse</div>
                  <div class="container plr-30 ptb-20 basis-75 flex align-center">The main axis runs bottom to top.</div>
                </div>

              </div>
              </div>

            </div>
          </section>

        <section id="justify-content">
          <div class="content-band">
          <h2>Justify content</h2>
          <p>
            Use these classes to distribute space between and around flex items along the main axis of the container.
          </p>

            <div class="mt-30"> 

              <div class="flex flex-col max-w800 white">

                <div class="flex">
                  <div class="container plr-30 ptb-20 basis-25 gray-250">Utility class</div>
                  <div class="container plr-30 ptb-20 basis-75 gray-250">Description</div>
                </div>
                
                <div class="flex border-bottom">
                  <div class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100">.justify-start</div>
                  <div class="container plr-30 ptb-20 basis-75 flex align-center">Justify all items to the left</div>
                </div>

                <div class="flex border-bottom">
                  <div class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100">.justify-center</div>
                  <div class="container plr-30 ptb-20 basis-75 flex align-center">Justify items to the center of the container</div>
                </div>

                <div class="flex border-bottom">
                  <div class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100">.justify-end</div>
                  <div class="container plr-30 ptb-20 basis-75 flex align-center">Justify all items to the right</div>
                </div>

                <div class="flex border-bottom">
                  <div class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100">.justify-between</div>
                  <div class="container plr-30 ptb-20 basis-75 flex align-center">Distribute items evenly. First item is on the start line, last item is on the end line.</div>
                </div>

                <div class="flex">
                  <div class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100">.justify-around</div>
                  <div class="container plr-30 ptb-20 basis-75 flex align-center">Distribute items evenly with equal space around them</div>
                </div>

              </div>
              </div>


          </div>
        </section>

        <section id="align-items">
          <div class="content-band" >
            <h2>Align items</h2>

            <p>
              Use these classes to align items on the cross axis.
            </p>

            <p>
              The cross axis runs perpendicular to the main axis. By default the main axis runs horizontally, 
              so your items will align vertically on the cross axis.
            </p>
            

            <div class="mt-30"> 

              <div class="flex flex-col max-w800 white">

                <div class="flex">
                  <div class="container plr-30 ptb-20 basis-25 gray-250">Utility class</div>
                  <div class="container plr-30 ptb-20 basis-75 gray-250">Description</div>
                </div>
                
                <div class="flex border-bottom">
                  <div class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100">.align-start</div>
                  <div class="container plr-30 ptb-20 basis-75 flex align-center">Align items to the start of the cross axis</div>
                </div>

                <div class="flex border-bottom">
                  <div class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100">.align-center</div>
                  <div class="container plr-30 ptb-20 basis-75 flex align-center">Align items to the center of the cross axis</div>
                </div>

                <div class="flex border-bottom">
                  <div class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100">.align-end</div>
                  <div class="container plr-30 ptb-20 basis-75 flex align-center">Align items to the end of the cross axis</div>
                </div>

                <div class="flex border-bottom">
                  <div class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100">.align-baseline</div>
                  <div class="container plr-30 ptb-20 basis-75 flex align-center">Align items along their baselines</div>
                </div>

                <div class="flex">
                  <div class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100">.align-stretch</div>
                  <div class="container plr-30 ptb-20 basis-75 flex align-center">Stretch items from start of cross axis to end of cross axis</div>
                </div>

              </div>
              </div>

          </div>
        </section>

        <section id="flex-basis">
          <div class="content-band" >
            <h2>Flex basis</h2>
            <p>
              The flex-basis property specifies the initial length of a flexible item. 
              Use these classes to assign length percentages to flex-items.
            </p>

            <div class="container mt-20 max-h300 max-w800 overflow-scroll white">
            

                  <div class="container plr-30 ptb-10 flex align-center font-white gray-800 font-15">Flex Basis Utilties</div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.basis-10</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">flex: 1 1 10%</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.basis-20</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">flex: 1 1 20%</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.basis-30</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">flex: 1 1 30%</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.basis-40</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">flex: 1 1 40%</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.basis-50</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">flex: 1 1 50%</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.basis-60</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">flex: 1 1 60%</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.basis-70</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">flex: 1 1 70%</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.basis-80</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">flex: 1 1 80%</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.basis-90</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">flex: 1 1 90%</div>
                  </div>

                  <div class="flex">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.basis-100</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">flex: 1 1 100%</div>
                  </div>

            </div>
          

          </div>
        </section>

        <section id="gap">
          <div class="content-band">
            <h2>Gap</h2>
            <p>
              The gap property defines the size of the gap between flexbox items and css grid rows and columns.
            </p>

            <div class="container mt-20 max-h300 max-w800 overflow-scroll white">
            

                  <div class="container plr-30 ptb-10 flex align-center font-white gray-800 font-15">Flex Gap Utilties</div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.gap-1</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">gap: 1px</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.gap-2</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">gap: 2px</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.gap-3</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">gap: 3px</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.gap-4</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">gap: 4px</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.gap-5</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">gap: 5px</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.gap-10</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">gap: 10px</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.gap-20</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">gap: 20px</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.gap-30</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">gap: 30px</div>
                  </div>

                  <div class="flex border-bottom">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.gap-40</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">gap: 40px</div>
                  </div>

                  <div class="flex">
                    <div class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100">.gap-50</div>
                    <div class="container plr-30 ptb-20 basis-50 flex align-center min-w100">gap: 50px</div>
                  </div>

            </div>

          </div>
        </section>

      </div>

        <!-- <div class="container w200 p-20">
          <nav class="content-menu">
            <ol class="content-menu-list">
              <li class=""><a href="#flex-containers">Flex Containers</a></li>
              <li class=""><a href="#flex-direction">Flex Direction</a></li>
              <li class=""><a href="#justify-content">Justify Content</a></li>
              <li class=""><a href="#align-items">Align Items</a></li>
              <li class=""><a href="#flex-basis">Flex Basis</a></li>
              <li class=""><a href="#gap">Gap</a></li>
            </ol>
          </nav>
        </div> -->

        <StickyTOC />

      </div>
      </div>

      <SubFooter />
    </div>
  </div>
</template>

<script>
import StickyTOC from "../StickyTOC";
import TopLevelLeftNav from "../../components/TopLevelLeftNav";
import SubFooter from "../../components/SubFooter";
export default {
  name: "flexbox",
  components: {
    TopLevelLeftNav,
    StickyTOC,
    SubFooter,
  },
};
</script>

